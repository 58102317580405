

.blogViewContainer {
    /* border: 1px solid #46AD8D; */
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1em;
}

.blogView {
    background-color: #171a20;
    border: 1px solid #46AD8D;
    margin: 3em;
    padding: 3em;
    border-radius: 1em;
}

a:link {
  color: #46AD8D;
}

a:visited {
  color: #46AD8D;
}

a:hover {
  color: white;
}

a:active {
  color: #46AD8D;
}

a:link {
    text-decoration: none;
  }


.blogPostContainer {
  margin: 5em;
}
