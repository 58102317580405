@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300&family=Roboto&display=swap");


body {
  background-color:#1e2025;
  text-align: center;
  font-family: "IBM Plex Mono", monospace;
  color: white;
}



/* ************* */

.topNav {
  margin-bottom: 5em;
}

.menuContainer {
  background-color: #171a20;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #46AD8D;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
}

.pfp {
  background-color: #171a20;
  height: 8em;
  width: 8em;
  border-radius: 100%;
  border: 2px solid#46AD8D;
  margin-left: auto;
  margin-right: auto;
}

.contentContainer {
  background-color: #171a20;
  border: 1px solid#46AD8D;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  border-radius: 0.5em;
}
